import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  createTheme,
  TableCell,
  CircularProgress,
  LinearProgress,
  ThemeProvider,
  Typography,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  TableFooter,
  Table,
  Paper,
} from "@material-ui/core";
import axios from "axios";
import { NewCoinList } from "../config/api";
import { useHistory } from "react-router-dom";
import { CryptoState } from "../CryptoContext";

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function titleCase(str) {
  return str.replace(/\w\S/g, function (t) {
    return t.toUpperCase();
  });
}

export default function CoinsTable() {
  const [coins, setCoins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page] = useState(1);

  const { currency, symbol } = CryptoState();

  const useStyles = makeStyles({
    row: {
      backgroundColor: "#16171a",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#131111",
      },
      fontFamily: "Roboto",
      align: "right",
    },
    pagination: {
      "& .MuiPaginationItem-root": {
        color: "white",
      },
    },
  });

  const classes = useStyles();
  const history = useHistory();

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      type: "dark",
    },
  });

  const fetchCoins = async () => {
    setLoading(true);
    const { data } = await axios.get(NewCoinList());
    setCoins(data);
    setLoading(false);
  };

  const getTotal = () => {
    return coins.reduce((acc, coin) => acc + coin.tvl, 0);
  };

  useEffect(() => {
    fetchCoins();
  }, [currency]);

  const tableData = () => {
    return coins;
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="xxl" style={{ textAlign: "center"  }}>
        <Typography variant="h4" style={{ margin: 18, fontFamily: "Roboto", color: "white" }}>
          Assets
        </Typography>
        <TableContainer component={Paper}>
          {loading ? (
            <LinearProgress
              style={{ color: "white" }}
              size={250}
              thickness={1}
            />
          ) : (
            <Table aria-label="simple table">
              <TableHead style={{ backgroundColor: "white" }}>
                <TableRow>
                  {["Asset", "Price", "Supply", "TVL"].map((head) => (
                    <TableCell
                      style={{
                        color: "black",
                        fontWeight: "700",
                        fontFamily: "Roboto",
                      }}
                      key={head}
                      align={head === "Asset" ? "left" : "right"}
                    >
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData().map((row) => {
                  return (
                    <TableRow
                      onClick={() => history.push(`/coins/${row.coin}`)}
                      className={classes.row}
                      key={row.name}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                      
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            align: "right",
                          }}
                        >
                          <span
                            style={{
                              textTransform: "uppercase",
                              fontSize: 22,
                            }}
                          >
                            {row.coin}
                          </span>
                          <span style={{ color: "darkgrey" }}>{row.name}</span>
                          <span><img
                            src={row.image}

                            height="50"
                            style={{ marginBottom: 10 }}
                          /></span>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        {symbol}{numberWithCommas(row.price.toFixed(2))}
                      </TableCell>
                      <TableCell align="right">
                        {numberWithCommas(row.supply.toFixed(2))}
                      </TableCell>
                      <TableCell align="right">
                        {symbol}{numberWithCommas(row.tvl.toFixed(2))}
                      </TableCell>
                    </TableRow>
                  );
                })}

                <TableRow style={{ backgroundColor: "white" }}>
                  <TableCell
                    style={{
                      color: "black",
                      fontWeight: "200",
                      fontFamily: "Roboto",
                    }}
                    align="right"
                    colSpan={3}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    style={{
                      color: "black",
                      fontWeight: "200",
                      fontFamily: "Roboto",
                    }}
                    align="right"
                  >
                    {symbol}{numberWithCommas(getTotal().toFixed(2))}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
}
