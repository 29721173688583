import axios from "axios";
import { Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { AverageRevenueData } from "../config/api";
import { AverageVolumeData } from "../config/api";
import { RevenueData } from "../config/api";
import { VolumeData } from "../config/api";
import { numberWithCommas } from "./CoinsTable";
import { makeStyles } from "@material-ui/core";
import { NewCoinList } from "../config/api";
import Title from "./Title";

function preventDefault(event) {
  event.preventDefault();
}

export default function AverageDetail() {
  const [averageRevenue, setAverageRevenue] = useState([]);
  const [averageVolume, setAverageVolume] = useState([]);
  const [revenue, setRevenue] = useState([]);
  const [volume, setVolume] = useState([]);
  const [loading, setLoading] = useState(false);
  const [symbol, setSymbol] = useState("$");
  const [coins, setCoins] = useState([]);

  const useStyles = makeStyles({
    change: {
      padding: 5,
      paddingLeft: 5,
      paddingRight: 5,
      fontFamily: "Roboto",
      width: "100%",
      margin: 6,
      fontWeight: 200,
      color: 'white'
    },
  });

  const classes = useStyles();

  const fetchAverageRevenue = async () => {
    setLoading(true);
    const { data } = await axios.get(AverageRevenueData());
    setAverageRevenue(data.toFixed(2));
    setLoading(false);
  };

  const fetchRevenue = async () => {
    setLoading(true);
    const { data } = await axios.get(RevenueData());
    setRevenue(data.toFixed(2));
    setLoading(false);
  };

  const fetchAverageVolume = async () => {
    setLoading(true);
    const { data } = await axios.get(AverageVolumeData());
    setAverageVolume(data.toFixed(2));
    setLoading(false);
  };

  const fetchVolume = async () => {
    setLoading(true);
    const { data } = await axios.get(VolumeData());
    setVolume(data.toFixed(2));
    setLoading(false);
  };

  const fetchCoins = async () => {
    setLoading(true);
    const { data } = await axios.get(NewCoinList());
    setCoins(data);
    setLoading(false);
  };

  const getTotal = () => {
    return coins.reduce((acc, coin) => acc + coin.tvl, 0);
  };

  useEffect(() => {
    fetchAverageRevenue();
    fetchAverageVolume();
    fetchRevenue();
    fetchVolume();
    fetchCoins();
  }, []);

  return (
    <React.Fragment>
      <Title>Average</Title>
      <Typography className={classes.change}>
        Revenue: {symbol}
        {numberWithCommas(averageRevenue)}
      </Typography>
      <Typography className={classes.change}>
        Volume: {symbol}
        {numberWithCommas(averageVolume)}
      </Typography>
      
    </React.Fragment>
  );
}
