import React from "react";
import CoinsTable from "../components/CoinsTable";
import AggregateTvl from "../components/AggregateTVLChart";
import AggregateBtcTvl from "../components/AggregateBTCChart";
import AggregateChart from "../components/AggregateChart";
// import { Box, Container, Grid, Paper } from "@material-ui/core";
import Copyright from "../components/Copyright";
import Test from "../components/Chart"
import AggregateTtl from "../components/AggregateTTLChart";
import MonthlyDetail from "../components/MonthlyDetail";
import AverageDetail from "../components/AverageDetail";
import AnnualizedDetail from "../components/AnnualizedDetail";
import TVLCard from "../components/TVLCard";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";


const Homepage = () => {
  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, margin: 5 }}>
        <Grid container spacing={2} columns={{ xs: 3, sm: 3, md: 12 }}>
          <Grid item xs={3} sm={3} md={3} key="tvl">
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              style={{
                padding: 12,
                background:
                  "linear-gradient(to right bottom, #3d3d3d, #000000)",
                border: "1px solid white",
              }}
            >
              <TVLCard />
            </Paper>
          </Grid>
          <Grid item xs={3} sm={3} md={3} key="1">
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              style={{
                padding: 12,
                background:
                  "linear-gradient(to right bottom, #3d3d3d, #000000)",
                border: "1px solid white",
              }}
            >
              <MonthlyDetail />
            </Paper>
          </Grid>
          <Grid item xs={3} sm={3} md={3} key="2">
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              style={{
                padding: 12,
                background:
                  "linear-gradient(to right bottom, #3d3d3d, #000000)",
                border: "1px solid white",
              }}
            >
              <AnnualizedDetail />
            </Paper>
          </Grid>
          <Grid item xs={3} sm={3} md={3} key="3">
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              style={{
                padding: 12,
                background:
                  "linear-gradient(to right bottom, #3d3d3d, #000000)",
                border: "1px solid white",
              }}
            >
              <AverageDetail />
            </Paper>
          </Grid>

          <Grid item xs={3} sm={3} md={12} key="4">
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              style={{
                padding: 12,
                background:
                  "linear-gradient(to right bottom, #3d3d3d, #000000)",
                border: "1px solid white",
              }}
            >
              <AggregateChart />
            </Paper>
          </Grid>
          <Grid item xs={3} sm={3} md={12} key="5">
            <Paper
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              style={{
                padding: 12,
                background:
                  "linear-gradient(to right bottom, #3d3d3d, #000000)",
                border: "1px solid white",
              }}
            >
              <CoinsTable />
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <Copyright />
    </React.Fragment>
  );
};

export default Homepage;
