import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Container,
  createTheme,
  TableCell,
  CircularProgress,
  LinearProgress,
  ThemeProvider,
  Typography,
  TableBody,
  TableRow,
  TableHead,
  TableContainer,
  Table,
  Paper,
  Button,
} from "@material-ui/core";
import axios from "axios";
import { ReservesList, NewCoinList } from "../config/api";
import { useHistory } from "react-router-dom";
import { CryptoState } from "../CryptoContext";

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function titleCase(str) {
  return str.replace(/\w\S/g, function (t) {
    return t.toUpperCase();
  });
}

export default function ReservesTable() {
  const [reserves, setReserves] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page] = useState(1);

  const { currency, symbol } = CryptoState();

  const useStyles = makeStyles({
    row: {
      backgroundColor: "#16171a",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "#131111",
      },
      borderBottom: 0,
      fontFamily: "Roboto",
      align: "right",
    },
    pagination: {
      "& .MuiPaginationItem-root": {
        color: "white",
      },
    },
    selectbutton: {
      border: "1px solid white",
      borderRadius: 5,
      padding: 5,
      paddingLeft: 20,
      paddingRight: 20,
      fontFamily: "Roboto",
      cursor: "pointer",
      backgroundColor: "white",
      color: "white",
      fontWeight: 200,
      "&:hover": {
        backgroundColor: "16171a",
        color: "black",
      },
      width: "20%",
      margin: 6,
    },
    alink: {
      color: "white",

      "&:hover": {
        color: "blue",
      },
    },
  });

  const classes = useStyles();

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      type: "dark",
    },
  });

  const fetchReserves = async () => {
    setLoading(true);
    const { data } = await axios.get(ReservesList());
    setReserves(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchReserves();
  }, [currency]);

  const tableData = () => {
    return reserves;
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Container maxWidth="xxl" style={{ textAlign: "center" }}>
        <Typography
          variant="h4"
          style={{ margin: 18, fontFamily: "Roboto", color: "white" }}
        >
          Reserves
        </Typography>
        <TableContainer component={Paper}>
          {loading ? (
            <LinearProgress
              style={{ color: "white" }}
              size={250}
              thickness={1}
            />
          ) : (
            <Table aria-label="simple table">
              <TableHead style={{ backgroundColor: "white" }}>
                <TableRow>
                  {["Asset", "Supply", "Reserves"].map((head) => (
                    <TableCell
                      style={{
                        color: "black",
                        fontWeight: "200",
                        fontFamily: "Roboto",
                      }}
                      key={head}
                      align={head === "Asset" ? "left" : "right"}
                    >
                      {head}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {tableData()
                  .slice((page - 1) * 20, (page - 1) * 20 + 20)
                  .map((row) => {
                    return (
                      <TableRow className={classes.row} key={row.token}>
                        <TableCell>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              align: "left",
                            }}
                          >
                            <span
                              align="left"
                              style={{
                                color: "white",
                                fontWeight: "200",
                                fontFamily: "Roboto",
                              }}
                            >
                              {row.token}
                            </span>
                            <span style={{ color: "darkgrey" }}></span>
                          </div>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: "white",
                            fontWeight: "200",
                            fontFamily: "Roboto",
                          }}
                        >
                          <a
                            href={row.supplyLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.alink}
                          >
                            {numberWithCommas(row.supply.toFixed(2))}{" "}
                            {row.token}
                          </a>
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{
                            color: "white",
                            fontWeight: "200",
                            fontFamily: "Roboto",
                          }}
                        >
                          <a
                            href={row.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className={classes.alink}
                          >
                            {numberWithCommas(row.reserves.toFixed(2))}{" "}
                            {row.underlying}
                          </a>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          )}
        </TableContainer>
      </Container>

      <div
        style={{
          display: "flex",
          color: "black",
          marginTop: 30,
          justifyContent: "space-around",
          width: "100%",
        }}
      ></div>
    </ThemeProvider>
  );
}
