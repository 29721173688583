import React from "react";
import CoinsTable from "../components/CoinsTable";
import { Box, Container, Grid, Paper } from "@material-ui/core";
import MonthlyDetail from "../components/MonthlyDetail";
import Copyright from "../components/Copyright";
import { TVLbyToken } from "../config/api";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import RevenueData from "../components/RevenueChart";
import { useLocation } from "react-router-dom";
import KPI from "../components/KPI";


const KPIPage = () => {
  const { id } = useParams();
  const [coin, setCoin] = useState();
  const [symbol, setSymbol] = useState("$");
  const location = useLocation();


  return (
    <>
      <div
        style={{
          display: "flex",
          color: "black",
          marginTop: 25,
          justifyContent: "column",
          width: "100%",
        }}
      ></div>
      <Box>
        <Container maxWidth="lg" sx={{ mt: 3, mb: 3 }}>
          <Grid sx={{ gap: 2 }} container spacing={3}>
            {/* Revenue Chart */}
            <Grid item xs={12}>
              <Paper
                sx={{
                  p: 5,
                  display: "flex",
                  flexDirection: "column",
                  height: 240,
                }}
                style={{
                  padding: 12,
                  background:
                    "linear-gradient(to right bottom, #3d3d3d, #000000)",
                  border: "1px solid white",
                }}
              >
                <KPI />
              </Paper>
            </Grid>
        
          </Grid>
          <Copyright sx={{ pt: 4 }} />
        </Container>
      </Box>
    </>
  );
};

export default KPIPage;