export const chartDays = [
  {
    label: "1D",
    value: 1,
  },
  {
    label: "7D",
    value: 7,
  },
  {
    label: "30D",
    value: 30,
  },
  {
    label: "90D",
    value: 90,
  },
  {
    label: "1Y",
    value: 365,
  },
  {
    label: "ITD",
    value: "ITD",
  },
  {
    label: "YTD",
    value: "YTD",
  },
];

export const chartSelector = [
  { label: "USD", value: "USD" },
  { label: "BTC", value: "BTC" },
];
