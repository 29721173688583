import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import { Menu as MenuIcon } from "@material-ui/icons";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { useHistory } from "react-router-dom";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";

const pages = ["Products", "Pricing", "Blog"];

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    color: "white",
    fontFamily: "Roboto",
    cursor: "pointer",
    float: "left",
  },
  menu: {
    float: "right",
  },
}));

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#grey",
    },
    type: "dark",
  },
});

const ResponsiveAppBar = () => {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const history = useHistory();
  const classes = useStyles();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="sticky" sx={{ bgcolor: "black" }}>
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            onClick={() => history.push(`/`)}
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src="https://ucarecdn.com/2702d2d3-6204-45f2-837e-1e911ae64929/"
              alt="icon"
              height="72"
            />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
            <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  id="home"
                  onClick={() => {
                    history.push(`/`);
                  }}
                  target="_blank"
                  color="inherit"
                  textAlign="center"
                >
                  Home
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  id="reserves"
                  onClick={() => {
                    history.push(`/reserves`);
                  }}
                  target="_blank"
                  color="inherit"
                  textAlign="center"
                >
                  Reserves
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  id="kpi"
                  onClick={() => {
                    history.push(`/kpi`);
                  }}
                  target="_blank"
                  color="inherit"
                >
                  KPIs
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  id="blog"
                  href="https://medium.com/wrapped"
                  target="_blank"
                  color="inherit"
                >
                  Blog
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  id="help"
                  href="mailto:help@wrapped.com"
                  color="inherit"
                >
                  Help
                </Button>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Button
                  id="sign-up"
                  href="https://tokensoft.typeform.com/to/BvAXNGPL?typeform-source=wrapped.com"
                  target="_blank"
                  color="inherit"
                >
                  Sign Up
                </Button>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            onClick={() => history.push(`/`)}
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              flexGrow: 1,
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            <img
              src="https://ucarecdn.com/2702d2d3-6204-45f2-837e-1e911ae64929/"
              alt="icon"
              height="72"
            />
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Button
              id="reserves"
              onClick={() => {
                history.push(`/reserves`);
              }}
              target="_blank"
              color="inherit"
            >
              Reserves
            </Button>
            <Button
              id="kpi"
              onClick={() => {
                history.push(`/kpi`);
              }}
              target="_blank"
              color="inherit"
            >
              KPIs
            </Button>
            <Button
              id="blog"
              href="https://medium.com/wrapped"
              target="_blank"
              color="inherit"
            >
              Blog
            </Button>
            <Button id="help" href="mailto:help@wrapped.com" color="inherit">
              Help
            </Button>
            <Button
              id="sign-up"
              href="https://tokensoft.typeform.com/to/BvAXNGPL?typeform-source=wrapped.com"
              target="_blank"
              color="inherit"
            >
              Sign Up
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;
