import { makeStyles } from "@material-ui/core";

const SelectButton = ({ children, selected, onClick }) => {
  const useStyles = makeStyles({
    selectbutton: {
      border: "1px solid white",
      borderRadius: 2,
      padding: 1,
      paddingLeft: 1,
      paddingRight: 1,
      fontFamily: "Roboto",
      cursor: "pointer",
      alignItems: "center",
      textAlign: "center",
      justifyContent: "center",
      backgroundColor: selected ? "white" : "",
      color: selected ? "black" : "white",
      fontWeight: selected ? 300 : 200,
      "&:hover": {
        backgroundColor: "white",
        color: "black",
      },
      width: "15%",
      margin: 2,
    },
  });

  const classes = useStyles();

  return (
    <span onClick={onClick} className={classes.selectbutton}>
      {children}
    </span>
  );
};

export default SelectButton;
