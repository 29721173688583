import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Line } from "react-chartjs-2";
import {
  createTheme,
  CircularProgress,
  ThemeProvider,
} from "@material-ui/core";
import axios from "axios";
import { TokenRevenueData } from "../config/api";
import { chartDays } from "../config/data";
import SelectButton from "./SelectButton";
import { numberWithCommas } from "./CoinsTable";


export default function RevenueData({ coin }) {
  const [revenue, setRevenue] = useState([]);
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(365);

  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 25,
      padding: 40,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: 0,
        padding: 20,
        paddingTop: 0,
      },
    },
    select: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      
    },

  }));

  const classes = useStyles();

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      type: "light",
    },
  });

  const fetchRevenue = async () => {
    setLoading(true);
    const { data } = await axios.get(TokenRevenueData(coin, days));
    setRevenue(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchRevenue();
  }, [days, coin]);

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={classes.container}>
        {loading ? (
          <CircularProgress
            style={{ color: "white" }}
            size={250}
            thickness={1}
          />
        ) : (
          <>
            <Line
              data={{
                labels: revenue.map((coin) => {
                  let date = coin.timestamp;
                  return date;
                }),

                datasets: [
                  {
                    data: revenue.map((coin) => coin.feeRevenue),
                    label: `Revenue by Day for ${coin}`,
                    borderColor: "white",
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: `Last ${days} Days Revenue: $${numberWithCommas(
                      revenue
                        .reduce((acc, rev) => acc + rev.feeRevenue, 0)
                        .toFixed(2)
                    )}`,
                  },
                },
                elements: {
                  point: {
                    radius: 1,
                  },
                },
              }}
            />
            <div
              className={classes.select}
            >
              {chartDays.map((day) => (
                <SelectButton
                  key={day.value}
                  onClick={() => {
                    setDays(day.value);
                  }}
                  selected={day.value === days}
                >
                  {day.label}
                </SelectButton>
              ))}
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
}
