import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from "chart.js";
import { Chart } from "react-chartjs-2";
import {
  createTheme,
  CircularProgress,
  ThemeProvider,
} from "@material-ui/core";
import axios from "axios";
import { KPIdata } from "../config/api";
import { numberWithCommas } from "./CoinsTable";
import { CryptoState } from "../CryptoContext";

export default function KPI() {
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );

  const [kpis, setKPIs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [days, setDays] = useState(365);
  const { currency, symbol } = CryptoState();

  const useStyles = makeStyles((theme) => ({
    container: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginTop: 25,
      padding: 40,
      [theme.breakpoints.down("md")]: {
        width: "100%",
        marginTop: 0,
        padding: 20,
        paddingTop: 0,
      },
    },
    select: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

  const classes = useStyles();

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      type: "light",
    },
  });

  const fetchKPIs = async () => {
    setLoading(true);
    const { data } = await axios.get(KPIdata());
    setKPIs(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchKPIs();
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <div className={classes.container}>
        {loading ? (
          <CircularProgress
            style={{ color: "white" }}
            size={250}
            thickness={1}
          />
        ) : (
          <>
            <Chart
              data={{
                labels: kpis.map((item) => {
                  let date = item.dateString;
                  return date;
                }),

                datasets: [
                  {
                    type: "line",
                    data: kpis.map((item) => item.movingAverage),
                    label: `4 Week MA`,
                    borderColor: "orange",
                    borderWidth: 2,
                    fill: false,
                  },
                  {
                    type: "bar",
                    data: kpis.map((item) => {
                      let goal = item.goal.toFixed(0);
                      return `${goal}`;
                    }),
                    label: `Volume Goal`,
                    backgroundColor: "white",
                    borderWidth: 2,
                  },
                  {
                    type: "bar",
                    data: kpis.map((item) => {
                      let actual = item.actual.toFixed(0);
                      return `${actual}`;
                    }),
                    label: `Volume Actuals`,
                    backgroundColor: "grey",
                    borderWidth: 2,
                  },
                  {
                    type: "bar",
                    data: kpis.map((item) => {
                      let actual = item.actual.toFixed(0);
                      let goal = item.goal.toFixed(0);
                      let diff = actual - goal
                      return `${diff}`;
                    }),
                    label: `Performance`,
                    backgroundColor: kpis.map((item) => {
                      let actual = item.actual.toFixed(0);
                      let goal = item.goal.toFixed(0);
                      let diff = actual - goal

                      if (diff >=0){
                        return "green"
                      } else{
                        return "red"
                      };
                    }),
                    borderWidth: 2,
                  },
                ],
              }}
              options={{
                scales: {
                  y: {
                    ticks: {
                      // Include a dollar sign in the ticks
                      callback: function (value) {
                        return "$" + numberWithCommas(value);
                      },
                    },
                  },
                },
                plugins: {
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        let label = context.dataset.label || "";

                        if (label) {
                          label += ": ";
                        }
                        if (context.parsed.y !== null) {
                          label += new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                          }).format(context.parsed.y);
                        }
                        return label;
                      },
                    },
                  },
                  legend: {
                    position: "top",
                  },
                  title: {
                    display: true,
                    text: `Actual vs. Goal Volumes by Week ($)`,
                  },
                },
              }}
            />
          </>
        )}
      </div>
    </ThemeProvider>
  );
}
