import React from "react";
import ReservesTable from "../components/ReserveTable";
import AggregateTvlData from "../components/AggregateTVLChart";
import AggregateChart from "../components/AggregateChart";
import { Box, Container, Grid, Paper } from "@material-ui/core";
import MonthlyDetail from "../components/MonthlyDetail";
import Copyright from "../components/Copyright";

const ReservePage = () => {
  return (
    <React.Fragment>
    <Box sx={{ flexGrow: 1, margin: 30 }}>
      <Grid container spacing={0} columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}>
        <Grid item xs={12} sm={12} md={12} lg={12} key="1">
          <Paper
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
            style={{
              padding: 12,
              background:
                "linear-gradient(to right bottom, #3d3d3d, #000000)",
              border: "1px solid white",
            }}
          >
            <ReservesTable />
          </Paper>
        </Grid>
      </Grid>
    </Box>

    <Copyright />
  </React.Fragment>
  );
};

export default ReservePage;
