import { makeStyles } from "@material-ui/core";
import Homepage from "./Pages/HomePage";
import "./App.css";
import { BrowserRouter, Route } from "react-router-dom";
import Header from "./components/Header";
import ReservePage from "./Pages/ReservePage"
import CoinPagev2 from "./Pages/CoinPage";
import kpiPage from "./Pages/KPI";
import ResponsiveAppBar from "./components/ResponsiveAppBar";


const useStyles = makeStyles(() => ({
  App: {
    background: 'linear-gradient(to right bottom, #3d3d3d, #0c0c0c)',
    color: "white",
    minHeight: "100vh",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <BrowserRouter>
      <div className={classes.App}>
        <ResponsiveAppBar />
        <Route path="/reserves" component={ReservePage} exact />
        <Route path="/" component={Homepage} exact />
        <Route path="/coins/:id" component={CoinPagev2} exact />
        <Route path="/kpi" component={kpiPage} exact />
        
      </div>
    </BrowserRouter>
  );
}

export default App;
