import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  AggregateBtcTvlDataByDays,
  AggregateBtcTvlChange,
} from "../config/api";
import { AggregateTvlDataByDays, AggregateTvlChange } from "../config/api";
import { chartDays } from "../config/data";
import SelectButton from "./SelectButton";
import { ResponsiveContainer } from "recharts";
import Title from "./Title";
import { Line } from "react-chartjs-2";
import { numberWithCommas } from "./CoinsTable";
import { Typography, ThemeProvider } from "@material-ui/core";
import { createTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { chartSelector } from "../config/data";

export default function AggregateChart() {
  const [aggregateTvl, setAggregateTvl] = useState([]);
  const [days, setDays] = useState("ITD");
  const [aggregateTvlChange, setAggregateTvlChange] = useState([]);
  const [symbol, setSymbol] = useState("BTC");
  const [chart, setChart] = useState("USD");

  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#fff",
      },
      type: "light",
    },
  });

  const useStyles = makeStyles({
    change: {
      padding: 5,
      paddingLeft: 20,
      paddingRight: 20,
      fontFamily: "Roboto",
      cursor: "pointer",
      width: "100%",
      margin: 6,
      fontWeight: 200,
      color: "white",
    },
  });

  const classes = useStyles();

  const fetchAggregateTvl = async () => {
    if (chart === "BTC") {
      const { data } = await axios.get(AggregateBtcTvlDataByDays(days));
      setAggregateTvl(data);
      setSymbol("BTC")
    } else {
      const { data } = await axios.get(AggregateTvlDataByDays(days));
      setAggregateTvl(data);
      setSymbol("USD")
    }
  };

  const fetchAggregateTvlChange = async () => {
    if (chart === "BTC") {
      const { data } = await axios.get(AggregateBtcTvlChange(days));
      setAggregateTvlChange(data);
      setSymbol("BTC")
    } else {
      const { data } = await axios.get(AggregateTvlChange(days));
      setAggregateTvlChange(data);
      setSymbol("USD")
    }
  };

  useEffect(() => {
    fetchAggregateTvl();
    fetchAggregateTvlChange();
  }, [days, chart]);

  return (
    <ThemeProvider theme={darkTheme}>
      <React.Fragment>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
            justifyContent: "space-around",
            width: "80%",
            marginRight: "10%",
            marginLeft: "10%",
          }}
        >
          {chartSelector.map((day) => (
            <SelectButton
              key={day.value}
              onClick={() => {
                setChart(day.value);
              }}
              selected={day.value === chart}
            >
              {day.label}
            </SelectButton>
          ))}
        </div>
        <Typography className={classes.change}>
          Change:{" "}
          {numberWithCommas(
            aggregateTvlChange.map((item) => item.change.toFixed(2))
          )}{" "}
          {symbol}
        </Typography>
        <Typography className={classes.change}>
          % Change:{" "}
          {numberWithCommas(
            aggregateTvlChange.map((item) => item.percent_change.toFixed(2))
          )}
          %
        </Typography>
        <ResponsiveContainer>
          <Line
            data={{
              labels: aggregateTvl.map((coin) => {
                let date = coin.date;
                return date;
              }),

              datasets: [
                {
                  data: aggregateTvl.map((coin) => coin.aggregateTvl),
                  label: `TVL`,
                  borderColor: "white",
                  padding: "40",
                },
              ],
            }}
            options={{
              plugins: {
                legend: {
                  position: "top",
                  padding: "40",
                },
              },
              elements: {
                point: {
                  radius: 1,
                },
              },
            }}
          />
        </ResponsiveContainer>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
            justifyContent: "space-around",
            width: "80%",
            marginRight: "10%",
            marginLeft: "10%",
          }}
        >
          {chartDays.map((day) => (
            <SelectButton
              key={day.value}
              onClick={() => {
                setDays(day.value);
              }}
              selected={day.value === days}
            >
              {day.label}
            </SelectButton>
          ))}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: 20,
            justifyContent: "space-around",
            width: "100%",
            marginRight: "0%",
            marginLeft: "10%",
          }}
        ></div>
      </React.Fragment>
    </ThemeProvider>
  );
}
