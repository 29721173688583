import * as React from "react";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Button,
} from "@material-ui/core";
import {
  createTheme,
  makeStyles,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { Menu } from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";
import { useState } from "react";
import { IconButton } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  title: {
    flex: 1,
    color: "white",
    fontFamily: "Roboto",
    cursor: "pointer",
  },
}));

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#grey",
    },
    type: "dark",
  },
});

function Header() {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const history = useHistory();

  return (
    <ThemeProvider theme={darkTheme}>
      <AppBar position="sticky">
        
          <Toolbar>
            <Typography
              onClick={() => history.push(`/`)}
              className={classes.title}
              styles={{ background: "black" }}
            >
              <img
                src="https://ucarecdn.com/2702d2d3-6204-45f2-837e-1e911ae64929/"
                alt="icon"
                height="72"
              />
            </Typography>

            <IconButton id="positioned-button" onClick={handleClick}>
              <MenuIcon />
            </IconButton>
            <Menu
              id="positioned-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
              transformOrigin={{ vertical: "top", horizontal: "center" }}
              disableEnforceFocus
            >
              <Button
                id="reserves"
                onClick={() => {
                  history.push(`/reserves`);
                  handleClose();
                }}
                target="_blank"
                color="inherit"
              >
                Reserves
              </Button>
              <Button
                id="kpi"
                onClick={() => {
                  history.push(`/kpi`);
                  handleClose();
                }}
                target="_blank"
                color="inherit"
              >
                KPIs
              </Button>
              <Button
                id="blog"
                href="https://medium.com/wrapped"
                target="_blank"
                color="inherit"
                onClick={handleClose}
              >
                Blog
              </Button>
              <Button
                id="help"
                href="mailto:help@wrapped.com"
                color="inherit"
                onClick={handleClose}
              >
                Help
              </Button>
              <Button
                id="sign-up"
                href="https://tokensoft.typeform.com/to/BvAXNGPL?typeform-source=wrapped.com"
                target="_blank"
                color="inherit"
                onClick={handleClose}
              >
                Sign Up
              </Button>
            </Menu>
          </Toolbar>
        
      </AppBar>
    </ThemeProvider>
  );
}

export default Header;
